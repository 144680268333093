import { useQuery } from 'react-query';
import { useContext } from 'react';
import { City, LmmMap, LmmRoad, Treatment } from '../shared/entity';
import { GlobalContext } from '../context/GlobalContext.js';
import DISPATCH_ACTIONS from '../context/actions.js';

export const GetTenantMap = () => {
  return useQuery<LmmMap>('/api/maps/one', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

export const GetLmmRoads = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<LmmRoad[]>('/api/roads', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    onSuccess(roads) {
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMROADS, payload: roads });
    },
  });
};

export const GetCities = () => {
  return useQuery<City[]>('/api/cities', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

export const GetTreaments = () => {
  return useQuery<Treatment[]>('/api/treatments', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};
