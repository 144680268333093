import { useEffect, useRef } from 'react';
import Zoomist from 'zoomist';
import type { ZoomistOptions } from 'zoomist/types';

export const Zoom = ({ src, alt, options }: { src: string; alt: string; options: ZoomistOptions }) => {
  const containerRef = useRef<HTMLDivElement>();
  const instance = useRef<Zoomist>();

  useEffect(() => {
    if (containerRef.current) {
      instance.current = new Zoomist(containerRef.current, options);
    }

    return () => {
      if (instance.current) {
        instance.current.destroy();
        instance.current = null;
      }
    };
  }, [options]);

  return (
    <div className="zoomist-container" ref={containerRef}>
      <div className="zoomist-wrapper">
        <div className="zoomist-image">
          <img src={src} alt={alt} />
        </div>
      </div>
    </div>
  );
};
