import type { DateLike } from './entity.js';

const formatter = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  year: 'numeric',
});

export function formatDate(date: DateLike): string {
  if (!date) {
    return '';
  }
  date = ensureDate(date);
  return formatter.format(date);
}

export function ensureDate(date: DateLike): Date {
  if (date instanceof Date) {
    return date;
  }
  return new Date(date);
}
