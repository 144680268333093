import styled from '@emotion/styled';

export const ProgressBarStyled = styled.progress<{ color: string }>((props) => ({
  display: 'block',
  fontSize: '0.25rem',
  color: props.color,
  backgroundColor: 'transparent',
  borderRadius: '3px',
  '&::-moz-progress-bar': {
    backgroundColor: 'transparent',
    borderRadius: '3px',
  },
  '&::-webkit-progress-bar': {
    backgroundColor: 'transparent',
    borderRadius: '3px',
  },
  '&::-webkit-progress-value': {
    backgroundColor: props.color,
    borderRadius: '3px',
  },
  width: '100%',
}));
