import { SVGProps } from 'react';
const PlanIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill={props.color || '#979BA6'}
      d="M5.46 2.93a2.46 2.46 0 0 0-2.469 2.469V8.46a2.46 2.46 0 0 0 2.47 2.469h3.061a2.46 2.46 0 0 0 2.47-2.47V5.4a2.46 2.46 0 0 0-2.47-2.47H5.46Zm8.531 0a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-6ZM8.147 5.586a.476.476 0 0 1 .688 0 .476.476 0 0 1 0 .688l-2 2a.476.476 0 0 1-.688 0l-1-1a.476.476 0 0 1 0-.688.476.476 0 0 1 .688 0l.656.656 1.656-1.656Zm5.844 3.344a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Zm-8.53 4a2.46 2.46 0 0 0-2.47 2.469v3.062a2.46 2.46 0 0 0 2.47 2.469h3.061a2.46 2.46 0 0 0 2.47-2.47V15.4a2.46 2.46 0 0 0-2.47-2.47H5.46Zm8.53 0a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-6Zm-5.844 2.656a.476.476 0 0 1 .688 0 .476.476 0 0 1 0 .688l-2 2a.476.476 0 0 1-.688 0l-1-1a.476.476 0 0 1 0-.688.476.476 0 0 1 .688 0l.656.656 1.656-1.656Zm5.844 3.344a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Z"
    />
  </svg>
);
export default PlanIcon;
