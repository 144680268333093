import {
  Box,
  Button,
  Center,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useUser } from '../hook/useUser.js';
import { ChangePasswordForm } from '../../user/ChangePasswordForm.js';
import { ComponentStyle } from '../../interface.js';
import { MyIcon, MyIconFilled } from '../component/Icons.js';
import { state } from '../state.js';

export function SettingsModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const [step, setStep] = useState<number>(0);
  const [modalTitle, setModalTitle] = useState<string>('Settings');

  useEffect(() => {
    if (step == 0) setModalTitle('Settings');
    else if (step == 1) setModalTitle('Change Password');
    else setModalTitle('Settings');
  }, [step]);

  const closeModal = () => {
    setStep(0);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="sm" returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent bg="var(--bg-color)" borderRadius="24px">
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton marginTop={2} />
        <Divider
          orientation="horizontal"
          color="var(--separator-color)"
          marginLeft="24px"
          marginRight="24px"
          width="auto"
        />
        <ModalBody p="2rem">
          {step === 0 && (
            <Box as="div">
              <div style={styles.settingsRow}>
                <MyIcon style={{ color: 'var(--border-color-lighter)', marginRight: '12px' }} icon="apartment" />
                <p>{state.user.name}</p>
              </div>
              <div style={styles.settingsRow}>
                <MyIcon style={{ color: 'var(--border-color-lighter)', marginRight: '12px' }} icon="alternate_email" />
                <p>{state.user.email}</p>
              </div>
              <div style={{ ...styles.settingsRow, cursor: 'pointer' }} onClick={() => setStep(1)}>
                <MyIconFilled style={{ color: 'var(--border-color-lighter)', marginRight: '12px' }} icon="lock" />
                <p>Change Password</p>
                <MyIcon
                  style={{ color: 'var(--border-color-lighter)', marginLeft: 'auto' }}
                  icon="keyboard_arrow_right"
                />
              </div>
              <form action="/logout" method="post">
                <Button
                  type="submit"
                  width="100%"
                  bg="#E76262"
                  leftIcon={<MyIcon style={{ color: 'white' }} icon="logout" />}
                >
                  Log Out
                </Button>
              </form>
            </Box>
          )}
          {step === 1 && <ChangePasswordForm onSuccess={onClose} onBack={() => setStep(0)} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const styles: ComponentStyle = {
  settingsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '24px',
    alignContent: 'center',
  },
};
