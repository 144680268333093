import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

export const theme = extendTheme({
  colors: {
    customBlue: {
      50: '#e6f2ff', // lightest shade
      100: '#cce0ff',
      200: '#3888ff',
      300: '#66a3ff',
      400: '#3385ff',
      500: '#3888ff', // base color
      600: '#3075e6',
      700: '#265bbf',
      800: '#1d4299',
      900: '#142966', // darkest shade
    },
  },
  config,
  styles: {
    global: (props: any) => {
      return {
        body: {
          color: mode('gray.800', 'whiteAlpha.900')(props),
          bg: mode(props.theme.semanticTokens.colors['chakra-body-bg']._light, 'var(--bg-color)')(props),
        },
      };
    },
  },
});
