import {
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { ChangePassword } from '../shared/entity.js';
import { MyIcon } from '../shared/component/Icons.js';

export function ChangePasswordForm({ onSuccess, onBack }: { onSuccess: () => void; onBack: () => void }) {
  const toast = useToast();
  const { register, handleSubmit, reset } = useForm<ChangePassword, ChangePassword, ChangePassword>();
  const [isSaving, setIsSaving] = useState<boolean>();
  const [showCurrent, setShowChurrent] = useState<boolean>();
  const [showNew, setShowNew] = useState<boolean>();

  const submit = handleSubmit((body) => {
    setIsSaving(true);
    fetch(`/api/password`, {
      body: JSON.stringify(body),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          toast({
            title: res.error,
            status: 'warning',
          });
        } else {
          onSuccess();
          toast({
            title: 'Password changed.',
            status: 'success',
          });
          reset();
        }
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: 'Oops! Something went wrong.',
          description:
            'An unexpected error occurred. Please try again later. If the problem persists, contact support.',
          status: 'error',
        });
      })
      .finally(() => setIsSaving(false));
  });

  return (
    <form
      onSubmit={submit}
      style={{
        display: 'grid',
        gap: '1.5rem',
      }}
    >
      <FormControl isRequired isDisabled={isSaving}>
        <FormLabel htmlFor="currentPassword">Current Password</FormLabel>
        <InputGroup>
          <Input
            {...register('currentPassword')}
            id="currentPassword"
            name="currentPassword"
            type={showCurrent ? 'text' : 'password'}
            placeholder="Current Password"
            minLength={6}
            required
          />
          <InputRightElement>
            <IconButton
              aria-label={showCurrent ? 'Hide' : 'Show'}
              title={showCurrent ? 'Hide' : 'Show'}
              size="sm"
              onClick={() => setShowChurrent(!showCurrent)}
              icon={<MyIcon icon={showCurrent ? 'visibility_off' : 'visibility'} />}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <FormControl isRequired isDisabled={isSaving}>
        <FormLabel htmlFor="password">New Password</FormLabel>
        <InputGroup>
          <Input
            {...register('password')}
            id="password"
            name="password"
            type={showNew ? 'text' : 'password'}
            placeholder="New Password"
            minLength={6}
            required
          />
          <InputRightElement>
            <IconButton
              aria-label={showNew ? 'Hide' : 'Show'}
              title={showNew ? 'Hide' : 'Show'}
              size="sm"
              onClick={() => setShowNew(!showNew)}
              icon={<MyIcon icon={showNew ? 'visibility_off' : 'visibility'} />}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <div style={{ display: 'flex' }}>
        <Button bg="#383839" style={{ flexGrow: '1', marginRight: '20px' }} onClick={onBack}>
          Back
        </Button>
        <Button type="submit" bg="#3888FF" style={{ flexGrow: '1' }} isDisabled={isSaving}>
          {isSaving ? 'Processing...' : 'Change'}
        </Button>
      </div>
    </form>
  );
}
