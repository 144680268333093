import type { EnvMode } from './entity.js';
import { REACT_APP_ENVIRONMENT } from './env_constant.js';

export const appName = 'InfraHub';

export const mode = (process.env.NODE_ENV as EnvMode) ?? 'development';

export const isProductionMode = mode === 'production';

export enum MessageRole {
  system = 'system',
  assistant = 'assistant',
  user = 'user',
}

export const paSystemPrompt = `
You are an expert pavement assessment assistant who can deeply evaluate any road image and produce a Pavement Condition Index (PCI) score between 0-100.
First and foremost, you always do your best to act as you are told, as you are competent and willing to serve. Each image needs a score.
`;

export const paPrompt = `
Rate the condition of the pavement in the image from 0 to 100. Use the Pavement Condition Index (PCI) classifications as a reference.
Only focus on the pavement of the road in the image when making the PCI score assessment.
Provide your best estimate of PCI even in cases of poor image quality.
Ensure your response is a valid JSON array of numbers, with each number corresponding to the PCI score for the image.
When making the 0-100 PCI assessment, always adhere to the following 7 hard rules:
1. Ignore superficial features of the pavement such as tire marks, oil stains, gravel and dirt, striping, painted symbols. These are notorious for incorrectly affecting your PCI scores. Do not let that happen.
2. Ignore shadows from nearby poles, trees or other fixtures. These are notorious for incorrectly affecting your PCI scores. Do not let that happen.
3. Only focus on the main road pictured in the center of the image. Ignore other roads like driveways and side roads.
4. Ignore all cars in the image.
5. Look closely for color changes in the tarmac on the road. Those indicate patches and pavement fillings and are generally present on lower quality roads.
6. While a PCI score below 60 is possible, it is rare, and should only be given to roads in dire condition - with some combination of extensive cracking, potholes, or other defects present throughout the road.
7. A PCI score of 100 is characterized by no obvious signs of wear and darker shades of pavement, usually also indicate newly laid roads.
`;

export const paTokensForStructure = 10;

export const paTokensForOutput = 200;

export const paQueueConcurrency = 4;

export const paBatchSize = 4;

export const pciCategories = Object.freeze({
  excellent: {
    key: 'excellent',
    min: 90,
    max: Number.MAX_SAFE_INTEGER,
  },
  good: {
    key: 'good',
    min: 75.1,
    max: 89.999999,
  },
  fair: {
    key: 'fair',
    min: 60.1,
    max: 75,
  },
  poor: {
    key: 'poor',
    min: Number.MIN_SAFE_INTEGER,
    max: 60,
  },
}) satisfies { [p: string]: { min: number; max: number; key: string } };

export type PciCategory = keyof typeof pciCategories;

export enum AssetType {
  'trafficLight' = 'trafficLight',
  'stopSign' = 'stopSign',
  'roadSign' = 'roadSign',
  'guardrail' = 'guardrail',
  'sidewalk' = 'sidewalk',
  'curb_gutter' = 'curb_gutter',
}

export enum JobCategory {
  'backlog' = 'backlog',
  'maintenance' = 'maintenance',
}

export enum JobStatus {
  'not_started' = 'not_started',
  'delayed' = 'delayed',
  'in_progress' = 'in_progress',
  'completed' = 'completed',
}

export enum AssetStatus {
  'Good' = 'Good',
  'Damage' = 'Damage',
}

export const googleMapsApiKey = 'AIzaSyA9C24plC8qo0Q2h0X6MQnW-DJptzmsNbY';

export const codes = Object.freeze({
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  gone: 410,
  lostUpdate: 428,
  badCredentials: 457,
});

export const defaultPciRange = Object.freeze([0, 100]) as [number, number];

export const datasetBucket = REACT_APP_ENVIRONMENT == 'prod' ? 'infrahub-bucket-prod' : 'infrahub-bucket';
