import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Box,
  Button,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepSeparator,
  useSteps,
  Checkbox,
  Select,
  Spinner,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.js';
import { ComponentStyle } from '../interface.js';
import AppInput from '../shared/component/AppInput.js';
import { MyIcon } from '../shared/component/Icons.js';
import { GetLmmRoads, GetTreaments } from '../query/mapqueries.js';
import SearchableInput from '../shared/component/SearchableInput.js';
import { JobCategory, JobStatus } from '../shared/const.js';
import { formatAsCurrency, removeAllCommas, textIsNumeric } from '../utils/stringUtils.js';
import { LmmJobResponse } from '../shared/entity.js';
import * as s from './CreateJobModal.module.css';

export function CreateJobModal({
  open,
  onClose,
  onCreateSuccess,
  job,
}: {
  open: boolean;
  onClose(): void;
  onCreateSuccess(): void;
  job: LmmJobResponse;
}) {
  const toast = useToast();
  const { height } = useScreenDimensions();

  const { data: roadsRef = [] } = GetLmmRoads();

  const { data: treatments = [] } = GetTreaments();
  const [isSubmitting, setIsSubmiting] = useState<boolean>(false);

  const { activeStep, setActiveStep, goToNext } = useSteps({
    index: 0,
    count: 2,
  });
  const [jobType, setJobType] = useState<string>();
  const initialFormState = {
    name: '',
    treatment: '',
    estimatedCost: '',
    startingMilepost: '',
    endingMilepost: '',
    plannedDate: '',
    category: JobCategory.backlog as string,
    status: JobStatus.not_started as string,
  };
  const [form, setForm] = useState(initialFormState);

  const onChangeJobType = (value: string) => {
    if (activeStep < 1) {
      goToNext();
    }
    setJobType(value);
  };

  useEffect(() => {
    if (job) {
      setForm({
        name: job.roadName,
        treatment: job.treatmentName,
        estimatedCost: formatAsCurrency(`${job.estimatedCost}`),
        plannedDate: job.plannedDate,
        startingMilepost: job.startingMilepost,
        endingMilepost: job.endingMilepost,
        category: job.category,
        status: job.status,
      });
      setActiveStep(2);
    } else {
      setForm(initialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, setActiveStep]);

  type FormKeys =
    | 'name'
    | 'treatment'
    | 'estimatedCost'
    | 'plannedDate'
    | 'category'
    | 'status'
    | 'startingMilepost'
    | 'endingMilepost';

  const updateFormValue = (key: FormKeys, value: string) => {
    const formCopy = { ...form };
    if (key == 'estimatedCost') {
      if (!textIsNumeric(value as string)) return;
      formCopy.estimatedCost = formatAsCurrency(value);
    } else {
      formCopy[key] = value;
    }
    setForm(formCopy);
    if (
      formCopy.category.length &&
      formCopy.estimatedCost.length &&
      formCopy.name.length &&
      formCopy.treatment.length
    ) {
      setActiveStep(2);
    }
  };

  const handleCreateJob = () => {
    const selectedRoad = roadsRef.find((x) => x.name == form.name);
    if (!selectedRoad) {
      toast({
        title: 'Please select a valid road',
        status: 'error',
      });
      return;
    }
    const selectedTreatment = treatments.find((x) => x.name == form.treatment);
    if (!selectedTreatment) {
      toast({
        title: 'Please select a valid treatment',
        status: 'error',
      });
      return;
    }
    const body = {
      plannedDate: form.plannedDate,
      estimatedCost: parseFloat(removeAllCommas(form.estimatedCost)),
      roadId: selectedRoad.id,
      treatmentId: selectedTreatment.id,
      status: form.status,
      category: form.category,
      startingMilepost: form.startingMilepost,
      endingMilepost: form.endingMilepost,
    };
    setIsSubmiting(true);
    fetch(`/api/jobs`, {
      body: JSON.stringify(body),
      method: 'post',
      headers: { 'content-type': 'application/json' },
    })
      .then((data) => {
        setIsSubmiting(false);
        toast({
          title: 'Job created successfully',
          status: 'success',
        });
        setForm(initialFormState);
        setActiveStep(0);
        onCreateSuccess();
        onClose();
      })
      .catch((err) => {
        setIsSubmiting(false);
        toast({
          title: 'Ops, job creation failed',
          status: 'error',
        });
      });
  };

  const handleUpdateJob = () => {
    const selectedRoad = roadsRef.find((x) => x.name == form.name);
    if (!selectedRoad) {
      toast({
        title: 'Please select a valid road',
        status: 'error',
      });
      return;
    }
    const selectedTreatment = treatments.find((x) => x.name == form.treatment);
    if (!selectedTreatment) {
      toast({
        title: 'Please select a valid treatment',
        status: 'error',
      });
      return;
    }
    const body = {
      plannedDate: form.plannedDate,
      estimatedCost: parseFloat(removeAllCommas(form.estimatedCost)),
      roadId: selectedRoad.id,
      treatmentId: selectedTreatment.id,
      status: form.status,
      category: form.category,
      startingMilepost: form.startingMilepost,
      endingMilepost: form.endingMilepost,
    };
    setIsSubmiting(true);
    fetch(`/api/jobs/${job.id}`, {
      body: JSON.stringify(body),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    })
      .then((data) => {
        setIsSubmiting(false);
        toast({
          title: 'Job updated successfully',
          status: 'success',
        });
        setForm(initialFormState);

        setActiveStep(0);
        onCreateSuccess();
        onClose();
      })
      .catch((err) => {
        setIsSubmiting(false);
        toast({
          title: 'Ops, job creation failed',
          status: 'error',
        });
      });
  };

  const onHandleClose = () => {
    setActiveStep(0);
    setForm(initialFormState);
    onClose();
  };

  return (
    <Modal
      blockScrollOnMount={false}
      isCentered
      isOpen={open}
      onClose={onHandleClose}
      returnFocusOnClose={false}
      closeOnEsc={false}
    >
      <ModalOverlay sx={{ background: 'rgba(0, 0, 0, 0.50);' }} />
      <ModalContent bg="var(--bg-color)" sx={{ width: '45%', minWidth: '500px', top: '20px', borderRadius: '24px' }}>
        <ModalBody px="32px" pt="32px">
          <ModalHeader mx="0px" px="0px" pt="0px">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <p style={{ fontSize: '24px', fontWeight: '700' }}>Create Job</p>
              <IconButton
                title="Open filter options"
                aria-label="Open filter options"
                icon={<MyIcon icon="close" style={{ color: '#979BA6' }} />}
                size="md"
                onClick={onHandleClose}
                sx={{ background: 'none', '&:hover': { background: 'none' } }}
              />
            </Box>
            <Box backgroundColor="var(--separator-color)" height="1px" width="100%" mt="16px" mb="8px"></Box>
          </ModalHeader>
          <Stepper index={activeStep} orientation="vertical" colorScheme="customBlue" gap="0">
            {[{}, {}].map((step, index) => (
              <Step key={index} style={{ width: '100%' }}>
                <StepIndicator marginRight="24px" borderColor="var(--border-color-lighter)">
                  <StepStatus complete={<MyIcon icon="radio_button_unchecked" />} incomplete={<></>} active={<></>} />
                </StepIndicator>

                {/* {index == 0 && (
                  <Box mb="32px">
                    <p style={styles.label}>Job Type</p>
                    <RadioGroup onChange={onChangeJobType} value={jobType}>
                      <Stack direction="row">
                        <Radio value="1" size="lg" colorScheme="customBlue">
                          <span style={styles.radioLabel}>Pavement</span>
                        </Radio>
                        <Radio value="2" size="lg" colorScheme="customBlue" color="#fff">
                          <span style={styles.radioLabel}>Roadside Asset</span>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                )} */}

                {index == 0 && (
                  <Box mb="32px" width="100%">
                    <p style={styles.label}>Route Details</p>
                    <SearchableInput
                      list={roadsRef}
                      id="roadName"
                      label="Road Name"
                      value={form.name}
                      labelStyles={styles.inputLabel}
                      selectOptionAction={(value) => {
                        setActiveStep(1);
                        updateFormValue('name', value);
                      }}
                      required
                      onChangeAction={() => {
                        updateFormValue('name', '');
                        setActiveStep(0);
                      }}
                    />
                    {/* <AppInput id="roadName" label="Road Name" /> */}
                    <Box display="flex" flexDirection="row" gap={10} mt="16px">
                      <AppInput
                        id="startingMilepost"
                        label="Starting Milepost"
                        labelStyles={styles.inputLabel}
                        onChange={(e) => updateFormValue('startingMilepost', e.target.value)}
                        value={form.startingMilepost}
                        autoComplete="off"
                      />
                      <AppInput
                        id="roadName"
                        label="Ending Milepost"
                        labelStyles={styles.inputLabel}
                        onChange={(e) => updateFormValue('endingMilepost', e.target.value)}
                        value={form.endingMilepost}
                        autoComplete="off"
                      />
                    </Box>
                    {/* <Checkbox
                      colorScheme="customBlue"
                      fontSize="14px"
                      fontWeight="500"
                      mt="16px"
                      // onChange={() => toggleRoadAssetView(AssetType.roadSign)}
                    >
                      <span style={{ color: 'var(--border-color-lighter)' }}>Select entire route</span>
                    </Checkbox> */}
                  </Box>
                )}

                {index == 1 && (
                  <Box mb="8px" width="100%">
                    <p style={styles.label}>Treatment Details</p>
                    <SearchableInput
                      list={treatments}
                      id="treatment"
                      label="Treatment"
                      required
                      value={form.treatment}
                      labelStyles={styles.inputLabel}
                      selectOptionAction={(value) => updateFormValue('treatment', value)}
                      onChangeAction={() => updateFormValue('treatment', '')}
                    />
                    <AppInput
                      id="cost"
                      label="Estimated Cost"
                      containerStyle={{ marginTop: '16px' }}
                      labelStyles={styles.inputLabel}
                      leftElement="$"
                      autoComplete="off"
                      required
                      onChange={(e) => updateFormValue('estimatedCost', e.target.value)}
                      value={form.estimatedCost}
                    />
                    <Box display="flex" flexDirection="row" gap={10} mt="16px">
                      <AppInput
                        id="plannedDate"
                        label="Planned Date"
                        labelSideText=" (Optional)"
                        labelSideTextStyles={{ color: '#979BA6' }}
                        type="date"
                        colorScheme="customBlue"
                        labelStyles={styles.inputLabel}
                        // defaultValue={new Date().toISOString().split('T')[0]}
                        onChange={(e) => updateFormValue('plannedDate', e.target.value)}
                        value={
                          form.plannedDate && form.plannedDate.length
                            ? new Date(form.plannedDate).toISOString().split('T')[0]
                            : ''
                        }
                      />
                      <Box w="100%">
                        <p style={{ marginBottom: '8px', ...styles.inputLabel }}>Category</p>
                        <Select
                          onChange={(e) => updateFormValue('category', e.target.value)}
                          mb="16px"
                          style={{ textOverflow: 'ellipsis', outline: 'none' }}
                          // defaultValue={JobCategory.backlog}
                          value={form.category}
                        >
                          <option value={JobCategory.backlog}>Backlog</option>
                          <option value={JobCategory.maintenance}>Maintenance</option>
                        </Select>
                      </Box>
                    </Box>

                    <Box w="100%">
                      <p style={{ marginBottom: '8px', ...styles.inputLabel }}>Status</p>
                      <Select
                        onChange={(e) => updateFormValue('status', e.target.value)}
                        mb="16px"
                        style={{ textOverflow: 'ellipsis', outline: 'none' }}
                        value={form.status}
                      >
                        <option value={JobStatus.not_started}>Not Started</option>
                        <option value={JobStatus.in_progress}>In Progress</option>
                        <option value={JobStatus.delayed}>Delayed</option>
                        <option value={JobStatus.completed}>Completed</option>
                      </Select>
                    </Box>

                    <Box display="flex" flexDirection="row" my="23px">
                      <Button marginRight="12px" onClick={onHandleClose} width="50%">
                        Cancel
                      </Button>
                      <Button
                        colorScheme="customBlue"
                        color="#fff"
                        onClick={!job ? handleCreateJob : handleUpdateJob}
                        isDisabled={activeStep != 2 || isSubmitting}
                        width="50%"
                      >
                        {!isSubmitting && (!job ? 'Create' : 'Save')}
                        {isSubmitting && (
                          <Spinner
                            width="25px"
                            height="25px"
                            thickness="3px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="gray.500"
                            size="xl"
                          />
                        )}
                      </Button>
                    </Box>
                  </Box>
                )}

                <StepSeparator style={{ ...(index >= activeStep && { backgroundColor: 'var(--separator-color)' }) }} />
              </Step>
            ))}
          </Stepper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const styles: ComponentStyle = {
  label: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '500',
    marginBottom: '16px',
    marginTop: '2px',
  },
  radioLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
  },
  inputLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
  },
};
