import { IconButton, Popover, PopoverArrow, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyIcon } from '../shared/component/Icons.js';
import MapIcon from '../asset/icons/MapIcon.js';
import PlanIcon from '../asset/icons/PlanIcon.js';
import ReportIcon from '../asset/icons/ReportIcon.js';
import ProfileIcon from '../asset/icons/ProfileIcon.js';
import SettingsIcon from '../asset/icons/SettingsIcon.js';
import { ComponentStyle } from '../interface.js';
import * as s from './MapMenu.module.css';

type MenuOption = 'Map' | 'Plan' | 'Report' | 'Profile' | 'Settings';

interface MapMenuOption {
  readonly name: MenuOption;
  readonly icon: (color: string) => JSX.Element;
  readonly to: string;
}

export function MapMenu({ screen }: { screen: string }) {
  const options = [
    { name: 'Map', icon: (color) => <MapIcon color={color} />, to: '/map' },
    { name: 'Plan', icon: (color) => <PlanIcon color={color} />, to: '/plan' },
    // { name: 'Report', icon: <ReportIcon />, to: '/metrics' },
    // { name: 'Profile', icon: <ProfileIcon />, to: '/account' },
    // { name: 'Settings', icon: <SettingsIcon />, to: '/settings' },
  ] satisfies MapMenuOption[];
  const [selectedMenu, setSelectedMenu] = useState(screen || 'Map');
  const navigate = useNavigate();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const onClickMenuOption = (option: MapMenuOption) => {
    setSelectedMenu(option.name);
    onClose();
    navigate(option.to);
  };

  useEffect(() => {
    setSelectedMenu(screen);
  }, [screen]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <IconButton
          className={s.hamburgerIconBox}
          title="Menu"
          aria-label="Menu"
          icon={<MyIcon icon="menu" />}
          size="sm"
        />
      </PopoverTrigger>
      <PopoverContent style={styles.content}>
        <PopoverArrow marginLeft="-20px" />
        {options.map((option) => (
          <div
            onClick={() => onClickMenuOption(option)}
            key={option.name}
            role="presentation"
            style={{
              ...styles.menuOption,
              backgroundColor: selectedMenu == option.name ? 'var(--color--blue)' : 'initial',
            }}
          >
            <span className="material-icons" color="#fff">
              {option.icon(selectedMenu == option.name ? '#fff' : 'rgb(151, 155, 166)')}
            </span>
            <p style={styles.optionName}>{option.name}</p>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
}

const styles: ComponentStyle = {
  content: {
    background: 'var(--bg-color)',
    left: 'var(---view-padding-m)',
    width: '140px',
  },
  menuOption: {
    display: 'flex',
    flexDirection: 'row',
    height: '48px',
    alignItems: 'center',
    padding: '0 var(---view-padding)',
    borderRadius: '6px',
    margin: '2px',
    cursor: 'pointer',
  },
  optionName: {
    marginLeft: '12px',
    fontWeight: '500',
    fontSize: '14px',
  },
};
