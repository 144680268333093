import { createContext, useReducer, ReactNode, Dispatch } from 'react';
import { LmmImage, LmmJobResponse, LmmRoad, LmmSection } from '../shared/entity';
import { JobCategory } from '../shared/const.js';
import DISPATCH_ACTIONS from './actions.js';

interface State {
  lmmImages: LmmImage[];
  imagesInView: LmmRoad[];
  lmmRoads: LmmRoad[];
  lmmSections: LmmSection[];
  topNavPlaceholder: {
    centerline: number;
    miles: number;
    averagePci: number;
    mapId: string;
    pci: {
      excellent: number;
      good: number;
      medium: number;
      poor: number;
    };
  };
  backLogJobs: LmmJobResponse[];
  maintenanceJobs: LmmJobResponse[];
}

const initialState: State = {
  lmmImages: [],
  lmmRoads: [],
  imagesInView: [],
  lmmSections: [],
  topNavPlaceholder: {
    centerline: 0,
    miles: 0,
    averagePci: 0,
    mapId: null,
    pci: {
      excellent: 0,
      good: 0,
      medium: 0,
      poor: 0,
    },
  },
  backLogJobs: [],
  maintenanceJobs: [],
};

interface Action {
  type: string;
  payload: any;
}

export const GlobalContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case DISPATCH_ACTIONS.SET_LMMIMAGES:
      return {
        ...state,
        lmmImages: action.payload,
      };
    case DISPATCH_ACTIONS.SET_LMMROADS:
      return {
        ...state,
        lmmRoads: action.payload,
      };
    case DISPATCH_ACTIONS.SET_IMAGESINVIEW:
      return {
        ...state,
        imagesInView: action.payload,
      };
    case DISPATCH_ACTIONS.SET_LMMSECTIONS:
      return {
        ...state,
        lmmSections: action.payload,
      };
    case DISPATCH_ACTIONS.SET_TOPNAVPLACEHOLDAER:
      if (action.payload) {
        localStorage.setItem('pciSegment', JSON.stringify(action.payload));
      }
      return {
        ...state,
        topNavPlaceholder: action.payload,
      };
    case DISPATCH_ACTIONS.SET_LMMJOBS:
      if (action.payload?.category == JobCategory.backlog) {
        return {
          ...state,
          backLogJobs: action.payload?.jobs,
        };
      } else {
        return {
          ...state,
          maintenanceJobs: action.payload?.jobs,
        };
      }
    default:
      return state;
  }
};

interface GlobalProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line react/prop-types
const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
