import { SVGProps } from 'react';
const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#979BA6"
      d="M8.968 2.456a9.669 9.669 0 0 0-3.719 2.187c-.333.31-.435.818-.219 1.219.801 1.48-.037 3.065-1.843 3.156-.443.023-.834.35-.938.78a8.712 8.712 0 0 0-.25 2.188c0 .688.074 1.464.219 2.156.09.433.466.744.906.783 1.818.156 2.718 1.543 1.906 3.311-.18.393-.098.863.219 1.156 1.062.983 2.282 1.675 3.719 2.125.41.13.873-.026 1.125-.375 1.112-1.537 2.725-1.543 3.781 0 .249.364.705.54 1.125.407a10.072 10.072 0 0 0 3.75-2.157c.33-.3.417-.787.219-1.186-.832-1.68.125-3.221 1.843-3.25.456-.008.862-.308.969-.75.173-.717.219-1.342.219-2.22 0-.754-.089-1.497-.25-2.218a.994.994 0 0 0-.969-.781c-1.691-.003-2.639-1.665-1.812-3.125a.979.979 0 0 0-.188-1.22 10.153 10.153 0 0 0-3.812-2.186.986.986 0 0 0-1.125.406c-.966 1.5-2.77 1.527-3.719.03-.243-.382-.724-.574-1.156-.436Zm3.031 5.53a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z"
    />
  </svg>
);
export default SettingsIcon;
