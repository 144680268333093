import { useCallback } from 'react';
import type { Geometry } from 'geojson';
import { UseRecenterParams } from './interface.js';

export const useMapRecenter = ({ mapRef, markersRef }: UseRecenterParams) => {
  return useCallback(
    (
      reference:
        | Geometry
        | google.maps.LatLng
        | google.maps.Data.Geometry
        | google.maps.Data
        | readonly google.maps.marker.AdvancedMarkerElement[] = markersRef?.current,
      zoomLevel?: number
    ) => {
      if (!mapRef.current) return;

      if (zoomLevel && reference instanceof google.maps.LatLng) {
        mapRef.current.setCenter(reference);
        mapRef.current.setZoom(zoomLevel);
      } else {
        if (reference instanceof google.maps.LatLng) {
          mapRef.current.setCenter(reference);
          return;
        }
        const bounds = new google.maps.LatLngBounds();
        if (reference instanceof google.maps.Data) {
          mapRef.current.data.forEach((feature) => feature.getGeometry().forEachLatLng((g) => bounds.extend(g)));
        } else if (Array.isArray(reference)) {
          (reference as google.maps.marker.AdvancedMarkerElement[]).forEach(({ position }) => bounds.extend(position));
        } else if (reference) {
          (reference as google.maps.Data.Geometry).forEachLatLng((latLng) => bounds.extend(latLng));
        }
        mapRef.current.fitBounds(bounds);
      }
    },
    [mapRef, markersRef]
  );
};
