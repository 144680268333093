// import Fuse from 'fuse.js';

export function capitalizeEachWord(str: string) {
  if (!str) return '';
  return str
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

export function fuzzySearchArray(str: string, arr: string[]) {
  // const options = {
  //   includeScore: true, // Include the score in the result
  //   threshold: 0.2, // Adjust the threshold for fuzzy matching
  // };

  // const fuse = new Fuse(arr, options);
  // const results = fuse.search(str);
  // return results.map((result) => result.item);
  return arr.filter((x) => x.toLowerCase().includes(str.toLowerCase()));
}

export function formatAsCurrency(x: string | number, returnZero?: boolean) {
  if (!x || x == '') return returnZero ? '0' : '';
  x = removeAllCommas(x as string);
  const parts = x.split('.');
  parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function formatAsCurrencyAlt(
  value: number | string,
  returnZero?: boolean,
  currency: string = 'USD',
  locale: string = 'en-US'
): string {
  if (!value || value == '') return returnZero ? '$0' : '';
  const numberValue = parseFloat(removeAllCommas(value as string));
  const formattedValue = new Intl.NumberFormat(locale, {
    ...(currency && { style: 'currency', currency: currency }),
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(numberValue);

  return formattedValue;
}

export function removeAllSpaces(input: string) {
  return input ? input.replace(/\s/g, '') : '';
}

export function removeAllCommas(input: string) {
  if (input) {
    const str = `${input}`;
    return str.replace(/,/g, '');
  }
  return input;
}

export const textIsDigits = (value: string, removeCommaFirst = true) => {
  let input = value;
  if (removeCommaFirst) {
    input = removeAllCommas(input);
  }
  const pattern = /^\d+$/;
  return pattern.test(input);
};

export const textIsNumeric = (value: string | number) => {
  const input = removeAllCommas(`${value}`);
  return !isNaN(Number(`${input}`));
};

export const getMonthYearDate = (value: string) => {
  const date = new Date(value);
  return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
};
