import { useQuery } from 'react-query';
import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { color } from 'framer-motion';
import TopNavBar from '../shared/component/TopNavBar.js';
import { useTitle } from '../shared/hook/useTitle.js';
import { LmmImage, LmmJob, LmmJobResponse } from '../shared/entity.js';
import { GlobalContext } from '../context/GlobalContext.js';
import DISPATCH_ACTIONS from '../context/actions.js';
import { ComponentStyle } from '../interface.js';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.js';
import { JobCategory, JobStatus } from '../shared/const.js';
import { capitalizeEachWord, formatAsCurrencyAlt, getMonthYearDate } from '../utils/stringUtils.js';
import { sumNumberArray } from '../utils/numberUtils.js';
import { MyIcon } from '../shared/component/Icons.js';
import { TopPageActions } from './TopPageActions.js';
import * as s from './Plan.module.css';

export function Plan() {
  const { state, dispatch } = useContext(GlobalContext);
  const { onOpen, onClose: onClosePopOver, isOpen } = useDisclosure();
  const { width, height } = useScreenDimensions();
  const [estimateCost, setEstimateCost] = useState<{ maintenance: number; backlog: number }>({
    maintenance: 0,
    backlog: 0,
  });
  const [jobInEdit, setJobInEdit] = useState<LmmJobResponse>(null);
  useTitle('Plan');

  const { refetch: refetchImages } = useQuery<LmmImage[]>('/api/images?pci%5B0%5D=0&pci%5B1%5D=100', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(newLmmImages) {
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMIMAGES, payload: newLmmImages });
    },
  });

  useEffect(() => {
    const estimatedCostCopy = { ...estimateCost };
    if (state.backLogJobs.length) {
      const costs = state.backLogJobs.map((job) => job.estimatedCost);
      estimatedCostCopy.backlog = sumNumberArray(costs);
    }
    if (state.maintenanceJobs.length) {
      const costs = state.maintenanceJobs.map((job) => job.estimatedCost);
      estimatedCostCopy.maintenance = sumNumberArray(costs);
    }
    setEstimateCost(estimatedCostCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.backLogJobs, state.maintenanceJobs]);

  const { refetch: refetchJobs } = useQuery<LmmJob[]>('/api/jobs', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(jobs) {
      const backLogJobs = jobs.filter((j) => j.category == JobCategory.backlog);
      const maintenanceJobs = jobs.filter((j) => j.category == JobCategory.maintenance);
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMJOBS, payload: { category: JobCategory.backlog, jobs: backLogJobs } });
      dispatch({
        type: DISPATCH_ACTIONS.SET_LMMJOBS,
        payload: { category: JobCategory.maintenance, jobs: maintenanceJobs },
      });
    },
  });

  const handleRefetchJobs = () => {
    refetchJobs();
  };

  const getStatusTags = (status: JobStatus) => {
    switch (status) {
      case JobStatus.in_progress:
        return (
          <Tag color="#ECA336" backgroundColor="rgba(236, 163, 54, 0.15);" borderRadius="24px" padding="4px 12px">
            In Progress
          </Tag>
        );
      case JobStatus.delayed:
        return (
          <Tag color="#E76262" backgroundColor="rgba(231, 98, 98, 0.15)" borderRadius="24px" padding="4px 12px">
            Delayed
          </Tag>
        );
      case JobStatus.completed:
        return (
          <Tag color="#3ECE80" backgroundColor="rgba(62, 206, 128, 0.15)" borderRadius="24px" padding="4px 12px">
            Completed
          </Tag>
        );

      default:
        return (
          <Tag
            color="var(--border-color-lighter)"
            backgroundColor="rgba(151, 155, 166, 0.15)"
            borderRadius="24px"
            padding="4px 12px"
          >
            Not Started
          </Tag>
        );
    }
  };

  const onClose = () => {
    setJobInEdit(null);
  };

  const handleRowClicked = (job: LmmJobResponse) => {
    setJobInEdit(job);
  };

  return (
    <Grid>
      <Box sx={{ mx: '24px' }}>
        <TopNavBar screen="Plan" />
        <TopPageActions refetchJobs={handleRefetchJobs} onClose={onClose} job={jobInEdit} />
      </Box>

      <Box marginTop="18px" overflowY="auto" height={`${height - 190}px`} sx={{ px: '24px' }}>
        <Accordion defaultIndex={[0, 1]} allowMultiple p="0px">
          <AccordionItem p="16px" mb="16px" backgroundColor="#383839" border="1px solid #464647" borderRadius="12px">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: '0 0 16px 0',
                borderBottom: '2px solid #464647',
              }}
            >
              <AccordionButton width="40%" p="0px" m="0px">
                <AccordionIcon color="var(--border-color-lighter)" marginRight="16px" ml="10px" />
                <p style={{ fontSize: '18px', fontWeight: '600' }}>Maintenance Plan</p>
                <Box
                  sx={{
                    color: '#3888FF',
                    backgroundColor: 'rgba(56, 136, 255, 0.15)',
                    fontSize: '14px',
                    fontWeight: 500,
                    padding: '4px 12px',
                    borderRadius: '50px',
                    marginLeft: '16px',
                  }}
                >
                  {`${state.maintenanceJobs.length} Job${state.maintenanceJobs.length > 1 ? 's' : ''}`}
                </Box>
              </AccordionButton>
              <Box>
                {/* <p style={{ fontSize: '16px', fontWeight: '500', margin: 0, padding: 0 }}>
                  <span style={{ color: 'var(--border-color-lighter)', fontWeight: '400', marginRight: '5px' }}>
                    Budget:
                  </span>
                  $236.2K / $300K
                </p> */}
                <p style={{ fontSize: '16px', fontWeight: '500', margin: 0, padding: 0 }}>
                  <span style={{ color: 'var(--border-color-lighter)', fontWeight: '400', marginRight: '5px' }}>
                    Estimated Cost:
                  </span>
                  {formatAsCurrencyAlt(estimateCost.maintenance, true)}
                </p>
              </Box>
            </Box>

            <AccordionPanel p={0}>
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr height="56px">
                      <Th sx={styles.checkBox} width="40px">
                        <Checkbox borderColor="#979BA6" />
                      </Th>
                      <Th sx={styles.tableHeader} width={`${width * 0.15}px`}>
                        STREET NAME
                      </Th>
                      <Th sx={styles.tableHeader}>STARTING MILEPOST</Th>
                      <Th sx={styles.tableHeader}>ENDING MILEPOST</Th>
                      <Th sx={styles.tableHeader}>STATUS</Th>
                      <Th sx={styles.tableHeader} textAlign="right">
                        PLANNED DATE
                      </Th>
                      <Th sx={styles.tableHeader}>TREATMENT</Th>
                      <Th sx={styles.tableHeader} textAlign="right">
                        ESTIMATED COST
                      </Th>
                      <Th sx={styles.tableHeader}>OWNER</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody sx={styles.tableBody}>
                    {state.maintenanceJobs?.length ? (
                      state.maintenanceJobs.map((job, index) => (
                        <Tr key={index} sx={styles.tableRow} onClick={() => setJobInEdit(job)}>
                          <Td sx={styles.checkBox}>
                            <Checkbox borderColor="#979BA6" />
                          </Td>
                          <Td sx={styles.tableData}>{capitalizeEachWord(job.roadName)}</Td>
                          <Td sx={styles.tableData}>{job.startingMilepost}</Td>
                          <Td sx={styles.tableData}>{job.endingMilepost}</Td>
                          <Td border="none">{getStatusTags(job.status)}</Td>
                          <Td sx={styles.tableData} textAlign="right">
                            {job.plannedDate?.length ? getMonthYearDate(job.plannedDate) : '-'}
                          </Td>
                          <Td sx={styles.tableData}>{job.treatmentName}</Td>
                          <Td sx={styles.tableData} textAlign="right">
                            {formatAsCurrencyAlt(job.estimatedCost)}
                          </Td>
                          <Td sx={styles.tableData}>{job.ownerName}</Td>
                          <Td>
                            <IconButton
                              // onClick={submit}
                              background="none"
                              type="button"
                              title="Actions"
                              aria-label="Actions"
                              icon={<MyIcon icon="more_vert" style={{ color: '#979BA6', fontSize: '25px' }} />}
                              size="sm"
                            />
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </Tbody>
                  {/* {!state.maintenanceJobs?.length && (
                    <Box width="100%">
                      <p style={{ textAlign: 'center', marginLeft: '15px' }}> No record found</p>
                    </Box>
                  )} */}
                </Table>
              </TableContainer>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem p="16px" backgroundColor="#383839" border="1px solid #464647" borderRadius="12px">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: '0 0 16px 0',
                borderBottom: '2px solid #464647',
              }}
            >
              <AccordionButton width="40%" p="0px" m="0px">
                <AccordionIcon color="var(--border-color-lighter)" marginRight="16px" ml="10px" />
                <p style={{ fontSize: '18px', fontWeight: '600' }}>Backlog</p>
                <Box
                  sx={{
                    color: '#3888FF',
                    backgroundColor: 'rgba(56, 136, 255, 0.15)',
                    fontSize: '14px',
                    fontWeight: 500,
                    padding: '4px 12px',
                    borderRadius: '50px',
                    marginLeft: '16px',
                  }}
                >
                  {`${state.backLogJobs.length} Job${state.maintenanceJobs.length > 1 ? 's' : ''}`}
                </Box>
              </AccordionButton>
              <Box>
                <p style={{ fontSize: '16px', fontWeight: '500', margin: 0, padding: 0 }}>
                  <span style={{ color: 'var(--border-color-lighter)', fontWeight: '400', marginRight: '5px' }}>
                    Estimated Cost:
                  </span>
                  {formatAsCurrencyAlt(estimateCost.backlog, true)}
                </p>
              </Box>
            </Box>

            <AccordionPanel p={0}>
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr height="56px">
                      <Th sx={styles.checkBox} width="40px">
                        <Checkbox borderColor="#979BA6" />
                      </Th>
                      <Th sx={styles.tableHeader} width={`${width * 0.15}px`}>
                        STREET NAME
                      </Th>
                      <Th sx={styles.tableHeader}>STARTING MILEPOST</Th>
                      <Th sx={styles.tableHeader}>ENDING MILEPOST</Th>
                      <Th sx={styles.tableHeader}>STATUS</Th>
                      <Th sx={styles.tableHeader} textAlign="right">
                        PLANNED DATE
                      </Th>
                      <Th sx={styles.tableHeader}>TREATMENT</Th>
                      <Th sx={styles.tableHeader} textAlign="right">
                        ESTIMATED COST
                      </Th>
                      <Th sx={styles.tableHeader}>OWNER</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody sx={styles.tableBody}>
                    {state.backLogJobs?.length ? (
                      state.backLogJobs.map((job, index) => (
                        <Tr key={index} sx={styles.tableRow} onClick={() => handleRowClicked(job)}>
                          <Td sx={styles.checkBox}>
                            <Checkbox borderColor="#979BA6" />
                          </Td>
                          <Td sx={styles.tableData}>{capitalizeEachWord(job.roadName)}</Td>
                          <Td sx={styles.tableData}>{job.startingMilepost}</Td>
                          <Td sx={styles.tableData}>{job.endingMilepost}</Td>
                          <Td border="none">{getStatusTags(job.status)}</Td>
                          <Td sx={styles.tableData} textAlign="right">
                            {job.plannedDate?.length ? getMonthYearDate(job.plannedDate) : '-'}
                          </Td>
                          <Td sx={styles.tableData}>{job.treatmentName}</Td>
                          <Td sx={styles.tableData} textAlign="right">
                            {formatAsCurrencyAlt(job.estimatedCost)}
                          </Td>
                          <Td sx={styles.tableData}>{job.ownerName}</Td>
                          <Td>
                            <IconButton
                              // onClick={submit}
                              type="button"
                              background="none"
                              title="Actions"
                              aria-label="Actions"
                              icon={<MyIcon icon="more_vert" style={{ color: '#979BA6', fontSize: '25px' }} />}
                              size="sm"
                            />
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </Tbody>
                  {/* {!state.backLogJobs?.length && (
                    <Box width="100%">
                      <p style={{ textAlign: 'center', marginLeft: '15px' }}> No record found</p>
                    </Box>
                  )} */}
                </Table>
              </TableContainer>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Grid>
  );
}

const styles = {
  tableHeader: {
    fontSize: '14px',
    lineHeight: '24px',
    color: 'var(--border-color-lighter)',
    fontWeight: '600',
    border: 'none',
  },
  tableData: {
    color: '#ffffff',
    fontSize: '16px',
    lineHeight: '24px',
    border: 'none',
  },
  checkBox: {
    border: 'none',
  },
  tableRow: {
    backgroundColor: '#464647',
    height: '56px',
    '&:hover': {
      backgroundColor: '#5a5a5b',
      cursor: 'pointer',
    },
  },
  tableBody: {
    backgroundColor: '#464647',
    borderRadius: '8px',
  },
};
