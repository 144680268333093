const DISPATCH_ACTIONS = {
  SET_LMMIMAGES: 'setLmmImages',
  SET_LMMROADS: 'setLmmRoads',
  SET_IMAGESINVIEW: 'setImagesInView',
  SET_TOPNAVPLACEHOLDAER: 'setTopNavPlaceholder',
  SET_LMMJOBS: 'setLmmJobs',
  SET_LMMSECTIONS: 'setLmmSections',
};

export default DISPATCH_ACTIONS;
