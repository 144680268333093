import type { Geometry, Point } from 'geojson';
import type { LmmImage, LmmRoadAsset, LmmSection, LmmSegment } from '../shared/entity.js';
import { AssetType, PciCategory, pciCategories } from '../shared/const.js';
import { PCI_COLORS } from '../utils/constants.js';

const colors = Object.freeze({
  excellent: PCI_COLORS.excellent,
  good: PCI_COLORS.good,
  fair: PCI_COLORS.fair,
  poor: PCI_COLORS.poor,
});

export function getSegmentImages(lmmImages: readonly LmmImage[], lmmSection: LmmSection) {
  return lmmImages.filter((i: LmmImage) => isSegmentImage(i, lmmSection));
}

export function isSegmentImage(lmmImage: LmmImage, lmmSection: LmmSection) {
  return (
    (lmmImage.sectionId && lmmImage.sectionId === lmmSection.id) ||
    (lmmImage.roadId && lmmImage.roadId === lmmSection.roadId)
  );
}

export function getSegmentMarkers(
  markers: readonly google.maps.marker.AdvancedMarkerElement[],
  lmmSection: LmmSection
) {
  return markers.filter((m) => isSegmentMarker(m, lmmSection));
}

export function isSegmentMarker(marker: google.maps.marker.AdvancedMarkerElement, lmmSection: LmmSection) {
  return (
    (marker.dataset.roadId && marker.dataset.roadId === lmmSection.roadId) ||
    (marker.dataset.sectionId && marker.dataset.sectionId === lmmSection.id)
  );
}

export function getColorFromPci(pci: number) {
  if (pci) {
    const categoryKey = getCategoryKeyFromPci(pci);
    return getColorFromCategoryKey(categoryKey);
  }
  return '#C0C0C0';
}

export function getColorFromCategoryKey(categoryKey: PciCategory) {
  return colors[categoryKey];
}

export function getCategoryKeyFromPci(pci: number = 0) {
  const vals = Object.values(pciCategories);
  return vals.find((c) => pci <= c.max && pci >= c.min).key as PciCategory;
}

export function buildFeatures(sections: readonly LmmSection[]) {
  return sections.map((section) => buildFeature(section));
}

export function buildFeature(section: LmmSection): { type: 'Feature' } & google.maps.Data.FeatureOptions {
  const { id, geometry, ...properties } = section;
  return {
    type: 'Feature',
    id,
    properties: {
      section: properties,
    },
    geometry: geometry as unknown as google.maps.Data.Geometry,
  };
}

export function buildSection(feature: google.maps.Data.Feature): LmmSection {
  const geometry = feature.getGeometry() as unknown as Geometry;
  return {
    id: feature.getId() as string,
    geometry,
    ...(feature.getProperty('section') as LmmSection),
  };
}

export const manualPci = 80;

export const envPartnersId = '75842067-146f-4322-9938-45aeac596032';

export function buildManualStopSignImage() {
  return {
    id: 'efb7007a-4f70-449d-80f1-2074444c79c2',
    tenantId: envPartnersId,
    pci: manualPci,
    assetType: AssetType.stopSign,
    createdAt: new Date('2024-02-29T16:44:47.768Z'),
    human: 0,
    path: 'dataset/demo_envpartners/stop-sign-good.png',
    tokens: 765,
    position: {
      type: 'Point',
      coordinates: [42.229513479, -70.97779074],
    },
  } satisfies LmmImage;
}

export function convertToGoogleMapsGeometry(geometryObj: Geometry): google.maps.Data.Geometry {
  switch (geometryObj.type) {
    case 'Point': {
      return new google.maps.Data.Point({ lat: geometryObj.coordinates[0], lng: geometryObj.coordinates[1] });
    }
    case 'LineString': {
      const lineStringCoords = geometryObj.coordinates.map((coord) => new google.maps.LatLng(coord[0], coord[1]));
      return new google.maps.Data.LineString(lineStringCoords);
    }
    case 'Polygon': {
      const polygonCoords = geometryObj.coordinates.map((ring) =>
        ring.map((coord) => new google.maps.LatLng(coord[0], coord[1]))
      );
      return new google.maps.Data.Polygon(polygonCoords);
    }
    case 'MultiLineString': {
      const multiLineStringCoords = geometryObj.coordinates.map((line) =>
        line.map((coord) => new google.maps.LatLng(coord[1], coord[0]))
      );
      return new google.maps.Data.MultiLineString(multiLineStringCoords);
    }
    // Dami - If needed add other specific cases . default should only run when, type is not provided.
    default: {
      const latLanCoords: google.maps.LatLng[] = [];
      (geometryObj as unknown as google.maps.Data.Geometry).forEachLatLng((e) => {
        latLanCoords.push(e);
      });
      return new google.maps.Data.LineString(latLanCoords);
    }
  }
}

export function addFeatureGroup(
  map: google.maps.Map,
  geometryGroup: Geometry[],
  groupId: string,
  color: string,
  setFeatureToDisplay: React.Dispatch<React.SetStateAction<{ [key: string]: google.maps.Polyline[] }>>
) {
  //adjust this functionality when handling more that guardrails
  const newGroup = geometryGroup.map((geometryObj, index) => {
    const latLngLiteralArray = (geometryObj as unknown as Point).coordinates.map(
      (coord: any) => new google.maps.LatLng(coord[1], coord[0])
    );
    const polyline = new google.maps.Polyline({
      path: latLngLiteralArray,
      geodesic: true,
      // strokeColor: color,
      strokeOpacity: 1,
      // strokeWeight: 4,
      // id: groupId,
      icons: [
        {
          icon: {
            path: 'M 0,-1 0,1',
            strokeColor: '#F4A460', // Color for the first segment
            strokeWeight: 4,
          },
          offset: '0',
          repeat: '20px',
        },
        {
          icon: {
            path: 'M 0,-1 0,1',
            strokeColor: '#000000', // Color for the second segment
            strokeWeight: 4,
          },
          offset: '10px',
          repeat: '20px',
        },
      ],
      map,
    });
    // polyline.setMap(map);
    return polyline;
  });

  setFeatureToDisplay((prevGroups) => ({
    ...prevGroups,
    [groupId]: newGroup,
  }));
}

export function removeFeatureGroup(
  groupId: string,
  setFeatureToDisplay: React.Dispatch<React.SetStateAction<{ [key: string]: google.maps.Polyline[] }>>
) {
  setFeatureToDisplay((prevGroups) => {
    const groupToRemove = prevGroups[groupId];
    if (groupToRemove) {
      groupToRemove.forEach((polyline) => polyline.setMap(null));
    }
    const { [groupId]: _, ...remainingGroups } = prevGroups;
    return remainingGroups;
  });
}

export function generateBoundsFromPoints(points: LmmImage[], miles: number) {
  if (!Array.isArray(points) || points.length === 0) {
    return null;
  }

  // Initialize variables to hold the minimum and maximum latitude and longitude
  let minLat = points[0].position.coordinates[0];
  let maxLat = points[0].position.coordinates[0];
  let minLng = points[0].position.coordinates[1];
  let maxLng = points[0].position.coordinates[1];

  // Iterate through the points to find the minimum and maximum latitude and longitude
  for (const point of points) {
    minLat = Math.min(minLat, point.position.coordinates[0]);
    maxLat = Math.max(maxLat, point.position.coordinates[0]);
    minLng = Math.min(minLng, point.position.coordinates[1]);
    maxLng = Math.max(maxLng, point.position.coordinates[1]);
  }

  // Convert miles to degrees
  const milesToDegreesLat = miles / 69; // 1 degree of latitude is approximately 69 miles
  const milesToDegreesLng = miles / (69 * Math.cos((((minLat + maxLat) / 2) * Math.PI) / 180)); // Adjust for longitude

  return {
    south: minLat - milesToDegreesLat,
    west: minLng - milesToDegreesLng,
    north: maxLat + milesToDegreesLat,
    east: maxLng + milesToDegreesLng,
  };
}
