import { SVGProps } from 'react';
const MapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill={props.color || '#fff'}
      fillRule="evenodd"
      d="m14.62 14.977-.004.004a2.707 2.707 0 0 1-4.232 0l-.003-.004a43.116 43.116 0 0 1-1.382-1.83v6.76l6 2v-7.413c-.138.178-.266.34-.38.483Zm2.379-3.31v10.24l4.313-1.439a.99.99 0 0 0 .687-.937v-12a.992.992 0 0 0-1.312-.937l-1.74.62c-.098.662-.317 1.301-.55 1.856-.33.782-.766 1.574-1.215 2.303l-.183.294ZM6.005 6.245l-3.318 1.13a.996.996 0 0 0-.688.937v12a.991.991 0 0 0 1.313.938l3.687-1.344V9.92a13.505 13.505 0 0 1-.395-.85C6.29 8.322 6 7.419 6 6.513c0-.09.002-.179.005-.267Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || '#fff'}
      d="M13.056 13.73C14.258 12.222 17 8.565 17 6.512A4.507 4.507 0 0 0 12.5 2C10.016 2 8 4.02 8 6.512c0 2.053 2.742 5.71 3.944 7.218.289.36.823.36 1.111 0ZM12.5 5.008A1.498 1.498 0 0 1 14 6.512a1.506 1.506 0 0 1-1.5 1.504A1.498 1.498 0 0 1 11 6.512a1.506 1.506 0 0 1 1.5-1.504Z"
    />
  </svg>
);
export default MapIcon;
