import { FormControl, FormLabel, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';
import { CSSProperties } from 'react';

interface ReusableInputProps extends InputProps {
  label: string;
  id: string;
  containerStyle?: CSSProperties;
  labelStyles?: CSSProperties;
  leftElement?: any;
  labelSideText?: string;
  labelSideTextStyles?: CSSProperties;
  required?: boolean;
}

const AppInput = ({
  label,
  id,
  containerStyle,
  leftElement,
  labelStyles,
  required,
  labelSideText,
  labelSideTextStyles,
  ...inputProps
}: ReusableInputProps) => {
  return (
    <FormControl w="100%" sx={containerStyle}>
      <FormLabel htmlFor={id} sx={labelStyles}>
        {label}
        {labelSideText && <span style={labelSideTextStyles}>{labelSideText}</span>}
        {required && <span style={{ color: '#E76262' }}>{` *`}</span>}
      </FormLabel>
      {!leftElement && <Input id={id} {...inputProps} />}
      {leftElement && (
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
            {leftElement}
          </InputLeftElement>
          <Input id={id} {...inputProps} />
        </InputGroup>
      )}
    </FormControl>
  );
};

export default AppInput;
